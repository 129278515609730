<template>
  <div :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="订单物流"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <!-- 订单 -->
    <div class="order_info" v-for="(item, i) in orderList" :key="i">
      <div class="info_num">
        <span
          >{{ item.Company ? item.Company + ": " : ""
          }}{{ item.LogisticsCode }}</span
        >
        <span class="info_status">{{
          item.Status == 0 ? "待收货" : "已收货"
        }}</span>
      </div>
      <div
        class="info_detail"
        v-for="(goods, j) in item.LogisticsDetailList"
        :key="j"
      >
        <img :src="goods.ImageUrl" alt="" />
        <div class="detail_content">
          <div>
            {{
              goods.GoodsName.length > 45
                ? goods.GoodsName.substr(0, 45) + "..."
                : goods.GoodsName
            }}
          </div>
          <div>
            <span>{{ goods.SpecName }}</span>
          </div>
          <!-- <div><span v-if="goods.Points>0">{{goods.Points}}积分+</span> ￥<span>{{goods.GoodsPrice}}</span></div> -->
        </div>
      </div>
      <!-- <div class="info_price">
        <div>共计{{item.GoodsCount}}套</div>
        <div>合计：<span>￥</span><span>{{item.TotalMoney}}</span></div>
      </div> -->
      <div class="info_btn" v-if="item.Status == 0">
        <!-- <div>查看物流</div> -->
        <div @click="confirm(item.Id)">确认收货</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import { queryShopLogisticsList, confirmReceipt } from "@/api/shop";
export default {
  data() {
    return {
      orderList: [],
      userInfo: {},
      orderId: 0,
      isWxApplets: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "订单物流";
      this.isWxApplets = false;
    }
    this.orderId = this.$route.query.orderId;
  },
  mounted() {
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.userInfo.Id = 0;
    }
    this.postQueryShopLogisticsList();
  },
  methods: {
    //确认收货
    confirm(id) {
      console.log(id, 888);
      Dialog.confirm({
        title: "确认收货",
        message: "确定商品已收到",
      })
        .then(() => {
          this.postConfirmReceipt(id);
        })
        .catch(() => {});
    },
    async postConfirmReceipt(id) {
      const res = await confirmReceipt(id);
      if (res.success == true) {
        Toast.success({
          message: res.msg,
        });
        this.postQueryShopLogisticsList();
      } else {
        Toast.fail({
          message: res.msg,
        });
      }
    },
    onClickLeft() {
      window.history.go(-1);
    },
    changeStatus() {
      console.log(this.active);
      this.orderList = [];
      this.pageIndex = 1;
      this.isEnd = false;
      this.postQueryShopOrderList();
    },
    // 列表数据
    async postQueryShopLogisticsList() {
      const res = await queryShopLogisticsList(this.orderId);
      this.orderList = res.response;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-ellipsis {
  color: #333333;
  font-size: 1rem;
  font-weight: 700;
}
/deep/ .van-nav-bar .van-icon {
  color: #333333;
}
//导航栏下边框
/deep/ .van-hairline--bottom::after {
  border: 0;
}
.order_info {
  background: #fff;
  width: 100%;
  // height: 15.375rem;
  box-sizing: border-box;
  padding: 0 0.9375rem;
  padding-bottom: 10px;
  margin-top: 0.625rem;
  .info_num {
    width: 100%;
    height: 2.9375rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 0.75rem;
    .info_status {
      color: #fc7134;
    }
  }
  .info_detail {
    display: flex;
    margin-bottom: 0.9375rem;
    img {
      width: 5.5625rem;
      height: 5.5rem;
      border-radius: 0.25rem;
      margin-right: 0.9375rem;
    }
    .detail_content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      div:first-child {
        font-size: 0.875rem;
        color: #333333;
        font-weight: 700;
        margin-right: 1.25rem;
      }
      div:nth-child(2) {
        font-size: 0.75rem;
        color: #858585;
        display: flex;
        justify-content: space-between;
      }
      div:nth-child(3) {
        font-size: 0.75rem;
        color: #fe4a4a;
        span {
          color: #fe4a4a;
          font-size: 0.9375rem;
          font-weight: 700;
        }
      }
    }
  }
  .info_price {
    display: flex;
    justify-content: flex-end;
    font-size: 0.875rem;
    color: #999999;
    margin-bottom: 0.875rem;
    div:first-child {
      margin-right: 1.25rem;
    }
    div:nth-child(2) {
      color: #333;
      span:nth-child(1) {
        font-size: 9px;
      }
      span:nth-child(2) {
        font-weight: 700;
      }
    }
  }
  .info_btn {
    display: flex;
    justify-content: flex-end;
    div {
      width: 5rem;
      height: 2.125rem;
      border-radius: 1.875rem;
      line-height: 2.125rem;
      text-align: center;
      font-size: 0.875rem;
      color: #333;
      border: 1px solid #e8e8e8;
    }
    div:nth-child(1) {
      background: linear-gradient(to right, #fc7134, #f54024);
      color: #fff;
      margin-left: 0.625rem;
      border: 0;
    }
  }
}
</style>
